/*--------------------------------------------------------------
TABLE OF CONTENT
----------------------------------------------------------------
1.0 VARIABLE
|  1.1 COLOR
|  1.2 TYPOGRAPHY
|  1.3 LAYOUT
2.0 FOUNDATION
3.0 TYPOGRAPHY
4.0 IMAGE
5.0 LAYOUT
6.0 COMPONENT
|  6.1 CTA
|  6.2 BUTTON
|  6.3 CARD
7.0 SECTION
|  7.1 HEADER
|  7.2 HERO
|  7.3 FEATURE
|  7.4 CLOSE
|  7.5 FOOTER
8.0 MEDIA
|  8.1 768PX
|  8.2 1024PX
|  8.3 1200PX
9.0 APP SPECIFIC
--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 VARIABLE
--------------------------------------------------------------*/

:root {

  /*------------------------------------------------------------
  |
  | 1.1 COLOR
  |
  ------------------------------------------------------------*/

  --ui-color-brand: #353535;

  /* COLOR PALETTE */

  --ui-color-n-000: #fff;
  --ui-color-n-100: #ebebeb;
  --ui-color-n-300: #aeaeae;
  --ui-color-n-500: #353535;
  --ui-color-n-700: #282828;
  --ui-color-n-900: #1a1a1a;

  /* BACKGROUND COLOR */
  
  --ui-color-background-primary  : var(--ui-color-n-000);
  --ui-color-background-secondary: var(--ui-color-n-100);

  /* TYPOGRAPHY COLOR */

  --ui-color-typography-heading: var(--ui-color-n-500);
  --ui-color-typography-body   : var(--ui-color-n-900);
  --ui-color-typography-note   : var(--ui-color-n-300);
  --ui-color-typography-button : var(--ui-color-n-000);

  /*------------------------------------------------------------
  |
  | 1.2 TYPOGRAPHY
  |
  ------------------------------------------------------------*/

  --ui-typography-typeface: "Inter", sans-serif;

  /* FONT SIZE */

  --ui-typography-h1: 1.9375rem;
  --ui-typography-h2: 1.5625rem;
  --ui-typography-h4: 1rem;
  --ui-typography-p : 1rem;
  --ui-typography-s : .8125rem;

  /* LEADING */

  --ui-typography-h1-leading: 1.2;
  --ui-typography-h2-leading: 1.2;
  --ui-typography-h4-leading: 1.25;
  --ui-typography-p-leading : 1.5;

  /* MARGIN */

  --ui-typography-margin-heading: .75rem;
  --ui-typography-margin-body   : 1.125rem;

  /*------------------------------------------------------------
  |
  | 1.3 LAYOUT
  |
  ------------------------------------------------------------*/
  
  --ui-layout-container: 1.25rem;
  --ui-layout-grid     : 3.625rem;
  --ui-layout-gutter   : 1rem;

  /* GAP */

  --ui-gap-cta    : .75rem;
  --ui-gap-hero   : 2rem;
  --ui-gap-feature: 2rem;
  --ui-gap-card   : 1.25rem;

  /* BORDER RADIUS */
  
  --ui-radius-button: 5rem;
  --ui-radius-card  : .5rem;

}

/*--------------------------------------------------------------
2.0 FOUNDATION
--------------------------------------------------------------*/

html { box-sizing: border-box; }

*,
*:before,
*:after { box-sizing: inherit; }

body {
  background-color: var(--ui-color-background-primary);
  color: var(--ui-color-typography-body);
  font-family: var(--ui-typography-typeface);
  font-feature-settings: "liga", "kern";
  font-size: var(--ui-typography-p);
  font-weight: 400;
  line-height: var(--ui-typography-p-leading);
  margin: 0 auto;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/*--------------------------------------------------------------
3.0 TYPOGRAPHY
--------------------------------------------------------------*/

a {
  color: var(--ui-color-brand);
  text-decoration: none;
}

h1,
h2,
h4,
p { margin-top: 0; }

h1,
h2,
h4 { color: var(--ui-color-typography-heading); }

h1,
h2 { margin-bottom: var(--ui-typography-margin-heading); }

h1 {
  font-size: var(--ui-typography-h1);
  line-height: var(--ui-typography-h1-leading);
}

h2 {
  font-size: var(--ui-typography-h2);
  line-height: var(--ui-typography-h2-leading);
}

h4 {
  font-size: var(--ui-typography-h4);
  line-height: var(--ui-typography-h4-leading);
}

p { margin-bottom: var(--ui-typography-margin-body); }

p:last-child { margin-bottom: 0; }

strong { font-weight: 700; }

small { font-size: var(--ui-typography-s); }

.ui-text-note {
  color: var(--ui-color-typography-note);
  line-height: 1;
}

/*--------------------------------------------------------------
4.0 IMAGE
--------------------------------------------------------------*/

img,
svg {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}

/*--------------------------------------------------------------
5.0 LAYOUT
--------------------------------------------------------------*/

.ui-layout-container {
  padding-left: var(--ui-layout-container);
  padding-right: var(--ui-layout-container);
}

.ui-layout-flex,
.ui-layout-grid {
  align-items: center;
  justify-content: center;
}

.ui-layout-flex { display: flex; }

.ui-layout-grid { display: grid; }

/*--------------------------------------------------------------
6.0 COMPONENT
--------------------------------------------------------------*/

/*--------------------------------------------------------------
|
| 6.1 CTA
|
--------------------------------------------------------------*/

.ui-component-cta {
  flex-direction: column;
  row-gap: var(--ui-gap-cta);
}

/*--------------------------------------------------------------
|
| 6.2 BUTTON
|
--------------------------------------------------------------*/

.ui-component-button {
  border: .0625rem solid var(--ui-color-brand);
  border-radius: var(--ui-radius-button);
  display: block;
  font-weight: 700;
  line-height: 1;
  text-align: center;
}

.ui-component-button-primary {
  background-color: var(--ui-color-brand);
  color: var(--ui-color-typography-button);
}

.ui-component-button-normal {
  padding: .75rem 1rem .875rem;
  width: fit-content;
}

.ui-component-button-small {
  font-size: var(--ui-typography-s);
  padding: .5rem .75rem .5625rem;
}

/*--------------------------------------------------------------
|
| 6.3 CARD
|
--------------------------------------------------------------*/

.ui-component-card {
  background-color: var(--ui-color-background-secondary);
  border-radius: var(--ui-radius-card);
  overflow: hidden;
  padding: 2.25rem 1.5rem;
  text-align: center;
  width: 100%;
}

.ui-component-card--image { margin-bottom: 2.25rem; }

.ui-component-card--title { margin-bottom: .5rem; }

/*--------------------------------------------------------------
7.0 SECTION
--------------------------------------------------------------*/

/*--------------------------------------------------------------
|
| 7.1 HEADER
|
--------------------------------------------------------------*/

.ui-section-header {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.ui-section-header__layout { justify-content: space-between; }

/*--------------------------------------------------------------
|
| 7.2 HERO
|
--------------------------------------------------------------*/

.ui-section-hero {
  padding-bottom: 5rem;
  text-align: center;
}

.ui-section-hero--image { margin-top: var(--ui-gap-hero); }

/*--------------------------------------------------------------
|
| 7.3 FEATURE
|
--------------------------------------------------------------*/

.ui-section-feature {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  text-align: center;
}

.ui-section-feature__layout {
  margin-top: var(--ui-gap-feature);
  row-gap: var(--ui-gap-card);
}

/*--------------------------------------------------------------
|
| 7.4 CLOSE
|
--------------------------------------------------------------*/

.ui-section-close {
  padding-bottom: 5rem;
  padding-top: 5rem;
  text-align: center;
}

.ui-section-close--logo {
  margin-bottom: var(--ui-typography-margin-body);
}

/*--------------------------------------------------------------
|
| 7.5 FOOTER
|
--------------------------------------------------------------*/

.ui-section-footer {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
}

.ui-section-footer__layout {
  column-gap: var(--ui-layout-gutter);
}

.ui-section-footer--copyright {
  margin-bottom: 0;
  margin-right: auto;
}

/*--------------------------------------------------------------
8.0 MEDIA
--------------------------------------------------------------*/

/*--------------------------------------------------------------
|
| 8.1 768PX
|
--------------------------------------------------------------*/

@media screen and (min-width: 48rem) {

  /*------------------------------------------------------------
  VARIABLE
  ------------------------------------------------------------*/
  
  :root {

    /*----------------------------------------------------------
    |
    | TYPOGRAPHY
    |
    ----------------------------------------------------------*/

    /* FONT SIZE */

    --ui-typography-h1: 2.1875rem;
    --ui-typography-h2: 1.75rem;
    --ui-typography-h4: 1.125rem;
    --ui-typography-p : 1.125rem;
    --ui-typography-s : .875rem;

    /* MARGIN */

    --ui-typography-margin-body: 1.25rem;

    /*----------------------------------------------------------
    |
    | LAYOUT
    |
    ----------------------------------------------------------*/

    --ui-layout-container: 4.25rem;
    --ui-layout-gutter   : 1.5rem;

    /* GAP */

    --ui-gap-hero   : 3rem;
    --ui-gap-feature: 3rem;
    --ui-gap-card   : 1.5rem;

  }

  /*------------------------------------------------------------
  LAYOUT
  ------------------------------------------------------------*/

  .ui-layout-container,
  .ui-layout-column-center {
    margin-left: auto;
    margin-right: auto;
  }

  .ui-layout-grid-3 {
    column-gap: var(--ui-layout-gutter);
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .ui-layout-grid-3 div:nth-of-type(3) {
    left: calc(50% + (var(--ui-layout-gutter) / 2));
    position: relative;
  }

  .ui-layout-column-6 {
    width: calc((var(--ui-layout-grid) * 6) +
           (var(--ui-layout-gutter) * 5));
  }

  /*------------------------------------------------------------
  COMPONENT
  ------------------------------------------------------------*/

  /*------------------------------------------------------------
  |
  | CARD
  |
  ------------------------------------------------------------*/

  .ui-component-card {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  /*------------------------------------------------------------
  SECTION
  ------------------------------------------------------------*/

  /*------------------------------------------------------------
  |
  | HEADER
  |
  ------------------------------------------------------------*/

  .ui-section-header {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  /*------------------------------------------------------------
  |
  | FEATURE
  |
  ------------------------------------------------------------*/

  .ui-section-feature {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }

  /*------------------------------------------------------------
  |
  | CLOSE
  |
  ------------------------------------------------------------*/

  .ui-section-close--logo { width: 4rem; }

  /*------------------------------------------------------------
  |
  | FOOTER
  |
  ------------------------------------------------------------*/

  .ui-section-footer {
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  
}

/*--------------------------------------------------------------
|
| 8.2 1024PX
|
--------------------------------------------------------------*/

@media screen and (min-width: 64rem) {

  /*------------------------------------------------------------
  VARIABLE
  ------------------------------------------------------------*/

  :root {

    /*----------------------------------------------------------
    |
    | LAYOUT
    |
    ----------------------------------------------------------*/

    --ui-layout-container: 0;
  
  }

  /*------------------------------------------------------------
  TYPOGRAPHY
  ------------------------------------------------------------*/

  a { transition: all 250ms ease; }

  a:not(.ui-component-button):hover {
    color: var(--ui-color-typography-body);
  }

  /*------------------------------------------------------------
  LAYOUT
  ------------------------------------------------------------*/
  
  .ui-layout-container { width: 60rem; }

  .ui-layout-grid-3 { grid-template-columns: repeat(3, 1fr); }

  .ui-layout-grid-3 div:nth-of-type(3) { position: static; }

}

/*--------------------------------------------------------------
|
| 8.3 1200PX
|
--------------------------------------------------------------*/

@media screen and (min-width: 75rem) {

  /*------------------------------------------------------------
  VARIABLE
  ------------------------------------------------------------*/

  :root {

    /*----------------------------------------------------------
    |
    | TYPOGRAPHY
    |
    ----------------------------------------------------------*/

    /* FONT SIZE */

    --ui-typography-h1: 2.75rem;
    --ui-typography-h2: 2.1875rem;
    --ui-typography-h4: 1.4375rem;

    /* MARGIN */

    --ui-typography-margin-heading: 1rem;
    --ui-typography-margin-body   : 1.75rem;

    /*----------------------------------------------------------
    |
    | LAYOUT
    |
    ----------------------------------------------------------*/

    --ui-layout-grid  : 4rem;
    --ui-layout-gutter: 2rem;

    /* GAP */
    
    --ui-gap-hero   : 4rem;
    --ui-gap-feature: 4rem;
    --ui-gap-card   : 2rem;
    
  }

  /*------------------------------------------------------------
  TYPOGRAPHY
  ------------------------------------------------------------*/

  .ui-text-intro { font-size: var(--ui-typography-h4); }

  /*------------------------------------------------------------
  LAYOUT
  ------------------------------------------------------------*/
  
  .ui-layout-container { width: 70rem; }

/*------------------------------------------------------------
  COMPONENT
  ------------------------------------------------------------*/

  /*------------------------------------------------------------
  |
  | BUTTON
  |
  ------------------------------------------------------------*/

  .ui-component-button-normal {
    padding-bottom: 1.125rem;
    padding-top: 1rem;
  }

  .ui-component-button-small {
    padding-bottom: .6875rem;
    padding-top: .625rem;
  }

  /*------------------------------------------------------------
  SECTION
  ------------------------------------------------------------*/

  /*------------------------------------------------------------
  |
  | HEADER
  |
  ------------------------------------------------------------*/

  .ui-section-header {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  /*------------------------------------------------------------
  |
  | HERO
  |
  ------------------------------------------------------------*/

  .ui-section-hero {
    padding-bottom: 7.5rem;
    padding-top: 7.5rem;
  }

  /*------------------------------------------------------------
  |
  | FEATURE
  |
  ------------------------------------------------------------*/

  .ui-section-feature {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  /*------------------------------------------------------------
  |
  | CLOSE
  |
  ------------------------------------------------------------*/

  .ui-section-close {
    padding-bottom: 7.5rem;
    padding-top: 7.5rem;
  }
  
  /*------------------------------------------------------------
  |
  | FOOTER
  |
  ------------------------------------------------------------*/

  .ui-section-footer {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }
}

/*--------------------------------------------------------------
9.0 APP SPECIFIC STYLES
--------------------------------------------------------------*/

/* Header Layout */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;  /* Increased padding */
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
}

.info-button {
  position: absolute;
  left: 1rem;
}

.logout-button {
  position: absolute;
  right: 1rem;
}

/* Center all form containers */
.mb-4 .mb-3,
.col-xs-12,
.col-md-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Center Form Groups and their contents */
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Center all labels and headings */
.form-label, 
h4, 
h5 {
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
}

/* Form Select with proper contrast for options */
.form-select {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
}

/* Style the options */
.form-select option {
  background-color: #2c2c2c;  /* Dark background for options */
  color: white;              /* White text */
}

/* Style the selected option */
.form-select option:checked {
  background-color: #0d6efd;  /* Bootstrap primary color */
  color: white;
}
/* Center all buttons and form controls */
.form-control,
.btn-group,
.btn {
  margin: 0 auto;
}

/* Center tag containers */
.d-flex.flex-wrap.gap-2 {
  justify-content: center;
  width: 100%;
}

/* Add margin to container to account for fixed header */
.container {
  margin-top: 4rem;
}

/* Modal Styling */
.modal-content {
  background-color: #2c2c2c;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-header .close {
  color: white;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* CreateMySquadReel Section */
.create-squadreel-section {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
  color: white;
}

/* Remove the bg-light class from your JSX and replace with this styling */
.create-squadreel-section.p-4.border.rounded {
  background-color: rgba(255, 255, 255, 0.05) !important;
}

/* Tag Buttons */
.rounded-pill {
  transition: all 0.2s ease;
  margin: 4px;
}

.rounded-pill:hover {
  transform: translateY(-2px);
}

/* Custom Checkbox */
.form-check-input {
  width: 1.2em;
  height: 1.2em;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.2);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/* Video Grid */
.video-grid {
  margin: 0 -12px;
  text-align: center;
}

/* Grid Column */
.video-grid .col-xs-12,
.video-grid .col-md-6,
.video-grid .col-lg-4 {
  display: flex;
  padding: 12px;
  height: 100%;
}

/* Video Card Styling */
.video-card {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.2s ease;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.video-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
}

/* Video Content Container */
.video-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Video Player Container */
.video-wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  width: 100%;
  margin-top: auto;
}

/* Video Card Header Area */
.video-card .video-title-area {
  min-height: 4rem;  /* Accommodates up to 2 lines of text */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  width: 100%;
}

/* Video Title */
.video-card .video-title {
  font-size: 1.1rem;
  line-height: 1.2;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;  /* Limits text to 2 lines */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  flex: 1;
  text-align: left;
}

/* Checkbox alignment */
.video-card .form-check {
  padding-left: 1rem;
  margin-top: 0.2rem; /* Aligns checkbox with first line of text */
}

/* Game title spacing */
.video-card .game-title {
  padding: 0 1rem 0.75rem 1rem;
  margin: 0;
  text-align: left;
}

/* Pagination Styling */
.pagination {
  margin-bottom: 2rem;
}

.pagination .page-link {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.pagination .page-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
}

.pagination .page-item.active .page-link {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: white;
}

.pagination .page-item.disabled .page-link {
  background-color: rgba(255, 255, 255, 0.02);
  border-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
}

/* Form Controls */
.form-control {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(13,110,253,0.25);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Form Select */
.form-select {
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  cursor: pointer;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-select:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(13,110,253,0.25);
}

/* Admin Controls */
.admin-controls {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

/* Buttons */
.btn {
  transition: all 0.2s ease;
  margin: 2px;
}

.btn:hover {
  transform: translateY(-1px);
}

.btn-outline-light {
  border-color: rgba(255, 255, 255, 0.2);
}

.btn-outline-light:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Modal List Styling */
.modal-body ol {
  padding-left: 20px;
  margin-bottom: 1.5rem;
}

.modal-body ol li {
  margin-bottom: 0.5rem;
  color: rgba(255, 255, 255, 0.9);
  text-align: left;
}

.modal-body h5 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: white;
}

/* Responsive Layout */
@media (max-width: 768px) {
  .video-card {
    margin-bottom: 20px;
  }
  
  .create-squadreel-section {
    margin: 20px 10px;
    padding: 15px;
  }

  .header-container {
    padding: 0.5rem;
  }

  .info-button,
  .logout-button {
    position: relative;
    left: auto;
    right: auto;
  }
}

/* Loading States */
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}