.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.paddeddiv{
  padding-bottom: 1em;
}

.responsive { 
  width: 10%; 
  height: auto; 
} 

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.create-squadreel-btn {
  /* Add your desired styles here */
  background-color: #007bff; /* Example blue background */
  color: white; /* Example white text */
  border-color: #007bff; /* Example blue border */
  padding: 10px 20px; /* Example padding */
  font-weight: bold; /* Example bold text */
}

.custom-checkbox {
  /* Adjust size as needed */
  width: 20px;
  height: 20px;
  appearance: none; /* Remove default checkbox appearance */
  background-color: #fff; /* Checkbox background color */
  border: 2px solid #ccc; /* Checkbox border */
  border-radius: 4px; /* Checkbox border radius */
  cursor: pointer; /* Allow hover effect */
}

.custom-checkbox:checked {
  background-color: #4CAF50; /* Checked checkbox background color */
  border-color: #4CAF50; /* Checked checkbox border color */
  /* Add a checkmark using pseudo-element */
  &::after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    margin: auto;
  }
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 0; /* Optional for spacing adjustments */
}

.season-selector select {
  background-color: #343a40;
  color: white;
  border: 1px solid #ced4da;
  margin-top: 1rem;
}